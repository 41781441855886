import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import lottie from "lottie-web";
import animationData from "../assets/lottie/rocket.json";

// SET THE COLORS HERE  -----------------------------

const color1 = [37, 37, 60];    // Corresponds to [0.145, 0.145, 0.235, 1]
const color2 = [93, 31, 93];    // Corresponds to [0.364, 0.121, 0.364, 1]
const color3 = [203, 145, 124]; // Corresponds to [0.796, 0.568, 0.486, 1]
const color4 = [206, 115, 180]; // Corresponds to [0.807, 0.450, 0.705, 1]

// ------------------------------------------------


function normalizeColors(color1, color2, color3, color4) {
  // Helper function to normalize a single color
  const normalize = (color) => color.map(value => value / 255);

  // Normalize each color and add alpha value
  const normalizedColor1 = [...normalize(color1), 1];
  const normalizedColor2 = [...normalize(color2), 1];
  const normalizedColor3 = [...normalize(color3), 1];
  const normalizedColor4 = [...normalize(color4), 1];

  // Combine into a color palette
  const colorPalette = [
    normalizedColor1, // Color 1
    normalizedColor2, // Color 2
    normalizedColor3, // Color 3
    normalizedColor4, // Color 4
  ];

  return colorPalette;
}

const LottieAnimation = () => {
  const colorPalette = normalizeColors(color1, color2, color3, color4);

  const containerRef = useRef(null);

  useEffect(() => {
    // Deep clone the animationData to avoid mutating the original data
    const clonedAnimationData = JSON.parse(JSON.stringify(animationData));

    // Apply the color palette to layers
    clonedAnimationData.layers.forEach((layer, index) => {
      const colorIndex = index % colorPalette.length; // Loop through the color palette
      const color = colorPalette[colorIndex]; // Get the current color
    
      // Check if 'shapes' exists and is an array before iterating
      if (Array.isArray(layer.shapes)) {
        layer.shapes.forEach((shape) => {
          // Check if 'it' exists and is an array before iterating
          if (Array.isArray(shape.it)) {
            shape.it.forEach((item) => {
              if (item.ty === "fl" || item.ty === "st") { // Check for fill and stroke types
                item.c.k = color; // Apply the color
              }
            });
          }
        });
      }
    });

    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => anim.destroy(); // Cleanup
  }, []);

  return (
    <>
      <div ref={containerRef} style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "50%",
        zIndex: -9999,
      }} />
      
    </>
  );
};

export default LottieAnimation;
