// This component plays a serie of PRE-RECORDED MP3 and display the corresponding caption based on the section number it receives.
// The goal was to skip the AI part at the early stages of the journey using pre-recorded audio

import React, { useEffect, useState, useRef } from 'react';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Button, Box, Paper } from '@mui/material';
import { ComponentName } from './DevAdmin';
import { useSelector, useDispatch } from 'react-redux';
import { storeValue, updateStep } from '../actions';
import sectionData from './sectionData.json'; // Import the JSON file
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const PlayRecordedSection = ({ sectionNumber, updateCurrentStep, currentStep }) => {
  //console.log('>>> COMPONENT PlayRecordedSection Beginning');
  const dispatch = useDispatch();
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [audioData, setAudioData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const audioRef = useRef();
  const cStep = currentStep;

  const cnextStep = Number(cStep) + 10;

  useEffect(() => {
    //console.log('Section Number Hook');
    setCurrentAudioIndex(0);
    setIsPlaying(true);

    // Load new audio data when sectionNumber changes
    const newAudioData = sectionData[sectionNumber].audioFiles.map(audioFile => {
      const audio = new Audio(audioFile);
      audio.onerror = function () {
        console.error('Error loading audio file:', audioFile);
      };
      return audio;
    });
    setAudioData(newAudioData);

    // Clean up
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [sectionNumber]);

  useEffect(() => {
    if (isPlaying && !isPaused && audioData[currentAudioIndex]) {
      audioRef.current = audioData[currentAudioIndex];
      audioRef.current.onended = () => {
        if (currentAudioIndex < audioData.length - 1) {
          setCurrentAudioIndex(currentAudioIndex + 1);
        } else {
          setIsPlaying(false);

          //Increase journey step unless it is an INPUT step
          if (cStep !== 90 && cStep !== 50) {
            //console.log('Current Step before update: ' + cStep);
            updateCurrentStep(cnextStep);
            console.log('EXITING PlayRecordedSections');
          }
        }
      };

      ///////////////////
      console.log(`\rProcessing Audio Index... ${currentAudioIndex + 1}/${audioData.length} - from |${ComponentName[cStep]}|`);

      // Update REDUX currentAudioIndex
      dispatch(storeValue('NumGameStepTracker', 'currentAudioIndex', currentAudioIndex + 1));

      //  If we reach the 5th line of the upsell speech at step 120, we show the funnel (set currentStep 130)
      if (currentAudioIndex === 5 && cStep === 120) {
        console.log("Upsell Audio should start at index 5, let's show the upsell page");
        updateCurrentStep(125);
      }

      audioRef.current.play();
    }
  }, [isPlaying, isPaused, audioData, currentAudioIndex, dispatch]);

  const handleSkipButtonClick = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying(false);
    setCurrentAudioIndex(audioData.length);

    updateCurrentStep(cnextStep);
  };

  const togglePausePlay = () => {
    if (audioRef.current) {
      if (isPaused) {
        // Resume playing the current audio
        audioRef.current.play();
      } else {
        // Pause the current audio
        audioRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
  };

  return (
    <div>
      <Box className="playRecorded-container">
        <h2>{isPlaying && audioData.length > 0 && <p>{sectionData[sectionNumber].texts[currentAudioIndex]}</p>}</h2>
      </Box>
      {isPlaying && (
        <button className="pause-play-button" onClick={togglePausePlay}>
          {isPaused ? <PlayIcon /> : <PauseIcon />}
        </button>
      )}
      {isPlaying && process.env.REACT_APP_RUNNING_ENV === 'development' && (
        <button className="skip-floating-button" onClick={handleSkipButtonClick}>
          <SkipNextIcon />
        </button>
      )}
    </div>
  );
};

export default PlayRecordedSection;
