import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import StripeCheckout from './StripeCheckout';

import { TextField, Box, Grid, Typography, Divider, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';

const colorHighlight = '#ffDA00';

// Custom styles
const PageContainer = styled(Box)({
  //   backgroundColor: "#1c1c3a",
  color: 'white',
  fontFamily: 'Montserrat, sans-serif',
  maxWidth: '1200px', // Set the max width to 1200px
  margin: '0 auto', // Center the content horizontally
  padding: '20px',
});

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center', // Centers content vertically
  height: '100%', // Full height of the container
  width: '100%', // Full width of the container
  textAlign: 'center', // Centers text horizontally

  // Breakpoint for small devices
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1),
  },

  // Breakpoint for medium devices
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },

  // Breakpoint for large devices
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const Spacer = styled('div')(({ height }) => ({
  height: height || '3rem', // Default height if not specified
}));

const FunnelCheckout = () => {
  console.log('Inside FunnelCheckout');

  const userFullname = useSelector(state => state.storeValue.Numerology.fullName);
  const userEmail = useSelector(state => state.storeValue.Numerology.email);

  return (
    <PageContainer>
      <Grid container>
        {/* First Row (HEADER) */}
        <Grid item xs={12}>
          <CenteredBox>
            <Typography variant="h1" style={{ fontSize: '42px', marginTop: 60, fontWeight: 600 }}>
              Get Your Personality Decoder Report
            </Typography>
            <Typography variant="h2" style={{ fontSize: '32px', fontWeight: 500 }}>
              For Only <span style={{ textDecoration: 'line-through' }}>$29.99</span>
            </Typography>
            <Typography
              variant="h2"
              style={{
                fontSize: '77px',
                fontWeight: 'bold',
                color: colorHighlight,
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              $14.00
            </Typography>
            <Spacer />
          </CenteredBox>
        </Grid>

        {/* Second Row (TESTIMONIALS + CHECKOUT) */}

        <Grid container item alignItems="flex-start">
          {/* TESTIMONIALS - Left Column (Will appear second on mobile) */}
          <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
            {/* Your testimonials content here */}
            <CenteredBox>
              <img
                src="/media/gfx/personality-decoder-inside-preview.png"
                alt="Product"
                style={{
                  marginTop: -50,
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                }}
              />
              <Typography style={{ fontSize: '32px', marginTop: 20, marginBottom: 20 }}>What Others Think:</Typography>
              <Typography style={{ fontStyle: 'italic', marginBottom: 5 }}>
                "...recommend it to anyone seeking a deeper understanding of themselves. It's not just a report, it's a journey of self-discovery."
              </Typography>
              <Typography
                style={{
                  textAlign: 'right',
                  marginBottom: 20,
                  color: colorHighlight,
                }}
              >
                -Maria Josefina, FL
              </Typography>
              <Typography style={{ fontStyle: 'italic', marginBottom: 5 }}>
                "Beyond just the revelations about my personality, the report also offered actionable advice on how to leverage my strengths and navigate challenges."
              </Typography>
              <Typography
                style={{
                  textAlign: 'right',
                  marginBottom: 20,
                  color: colorHighlight,
                }}
              >
                -Steven P, CO
              </Typography>
              <Typography style={{ fontStyle: 'italic', marginBottom: 5 }}>
                "...I felt as if the pages were written just for me. Each section delved deep into facets of my character that I hadn’t even fully acknowledged. It was uncanny how certain
                numerological aspects mirrored my life experiences, dreams, challenges, and aspirations."{' '}
              </Typography>
              <Typography
                style={{
                  textAlign: 'right',
                  marginBottom: 20,
                  color: colorHighlight,
                }}
              >
                -Debbie, AZ
              </Typography>

              <Divider
                style={{
                  backgroundColor: '#535062',
                  marginTop: 30,
                  marginBottom: 0,
                }}
              />

              <Typography variant="body1" color="Gray">
                Overall Ratings
              </Typography>
              <Rating defaultValue={4.5} precision={0.5} name="half-rating" />
              <Typography variant="body1" color="Gray">
                4.8/5 2.7k
              </Typography>
            </CenteredBox>
          </Grid>

          {/* CHECKOUT - Right Column (Will appear first on mobile) */}
          <Grid item xs={12} md={7} order={{ xs: 1, md: 2 }}>
            {/* Your checkout content here */}
            <CenteredBox>
              {/* Checkout content */}
              <StripeCheckout />
            </CenteredBox>
            <Spacer />
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default FunnelCheckout;
