export const storeValue = (parentKey, key, value) => ({
  type: "STORE_VALUE",
  payload: {
    parentKey,
    key,
    value,
  },
});

export const updateStep = (newStepValue) => ({
  type: "UPDATE_STEP",
  payload: newStepValue,
});

export const setIsFetching = (isFetching) => ({
  type: "SET_IS_FETCHING",
  payload: isFetching,
});
