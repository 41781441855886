// The Pythagorean mapping of letters to numbers
const PYTHAGOREAN_CHART = {
  a: 1,
  b: 2,
  c: 3,
  d: 4,
  e: 5,
  f: 6,
  g: 7,
  h: 8,
  i: 9,
  j: 1,
  k: 2,
  l: 3,
  m: 4,
  n: 5,
  o: 6,
  p: 7,
  q: 8,
  r: 9,
  s: 1,
  t: 2,
  u: 3,
  v: 4,
  w: 5,
  x: 6,
  y: 7,
  z: 8,
};

// LIFEPATH NUMBER CALCULATION ///////////////////////////////////////////////////////
function calculateLifePathNumber(birthDate) {
  // Helper function to reduce a number to a single digit or Master Number
  function reduceToSingleDigit(num) {
    while (num > 9 && num !== 11 && num !== 22 && num !== 33) {
      num = num
        .toString()
        .split('')
        .reduce((acc, digit) => acc + parseInt(digit, 10), 0);
    }
    return num;
  }

  // Since the month, day, and year are strings, parse them as integers
  const day = parseInt(birthDate.day, 10);
  const month = parseInt(birthDate.month, 10);
  const year = parseInt(birthDate.year, 10);

  // Reduce the day, month, and year to single digits or Master Numbers
  const reducedDay = reduceToSingleDigit(day);
  const reducedMonth = reduceToSingleDigit(month);
  const reducedYear = reduceToSingleDigit(year);
  const digitSum = day + month + year;
  //console.log(`Day: ${day} Month: ${month} Year: ${year} Sum: ${digitSum}`);

  // Sum the reduced numbers and reduce again to get the Life Path Number
  const lifePathNumber = reduceToSingleDigit(digitSum);
  //console.log(`Life Path Number: ${lifePathNumber}`);

  return lifePathNumber;
}

// EXPRESSION NUMBER CALCULATION ///////////////////////////////////////////////////////
function calculateExpressionNumber(fullName) {
  const lowerCaseName = fullName.toLowerCase();

  // Map each letter in the name to its corresponding number,
  // and reduce to a sum
  let sum = 0;
  for (let i = 0; i < lowerCaseName.length; i++) {
    if (lowerCaseName[i] !== ' ') {
      // skip spaces
      sum += PYTHAGOREAN_CHART[lowerCaseName[i]];
    }
  }
  //console.log("exp number sum: ", sum);

  // Reduce the sum to a single digit number, but don't reduce
  // master numbers 11 and 22
  if (sum === 11 || sum === 22) {
    return sum;
  }
  while (sum > 9) {
    sum = String(sum)
      .split('')
      .reduce((acc, curr) => acc + Number(curr), 0);
  }
  //console.log("exp number sum after reduction: ", sum);

  return sum;
}

// URGE or SOUL NUMBER CALCULATION ///////////////////////////////////////////////////////
function calculateUrgeNumber(fullName) {
  const lowerCaseName = fullName.toLowerCase();

  // Check if the name contains at least one vowel
  if (!/[aeiou]/.test(lowerCaseName)) {
    return; // Return nothing if no vowels are present
  }

  // Assuming PYTHAGOREAN_CHART is a predefined object mapping vowels to their numerology numbers
  let sum = 0;
  for (let i = 0; i < lowerCaseName.length; i++) {
    if ('aeiou'.includes(lowerCaseName[i])) {
      // only process vowels
      sum += PYTHAGOREAN_CHART[lowerCaseName[i]];
    }
  }

  // Reduce the sum to a single digit number, but don't reduce master numbers 11 and 22
  while (sum > 9 && sum !== 11 && sum !== 22) {
    sum = String(sum)
      .split('')
      .reduce((acc, curr) => acc + Number(curr), 0);
    if (sum === 11 || sum === 22) {
      break; // Keep the master numbers as they are
    }
  }

  return sum;
}

export { calculateLifePathNumber, calculateExpressionNumber, calculateUrgeNumber };
