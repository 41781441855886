import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";

const LottieAnimation = ({
  animationName,
  xPos,
  yPos,
  scale,
  zIndex,
  opacity = 100, // Default opacity set to 100%
  displayMode = 'floating', // 'floating' or 'inline-block'
  width = 300, // Default width in pixels
  height = 300, // Default height in pixels
  speed = 1, // Default speed is 1 (normal speed)
  boolLoop,
  onComplete,
  useThemeColors = 'theme', // 'theme', 'original', 'white'
}) => {
  const containerRef = useRef(null);
  const [animationData, setAnimationData] = useState(null);

  const normalizeColors = (color) => color.map(value => value / 255);
  
  // Theme color palette
  const themeColorPalette = [
    normalizeColors([37, 37, 60, 255]), // Adjusted for RGBA
    normalizeColors([93, 31, 93, 255]),
    normalizeColors([203, 145, 124, 255]),
    normalizeColors([206, 115, 180, 255]),
  ];
  const whiteColor = normalizeColors([255, 255, 255, 255]);

  useEffect(() => {
    import(`../assets/lottie/${animationName}.json`)
      .then(data => {
        setAnimationData(data.default ? data.default : data);
      })
      .catch(console.error);
  }, [animationName]);

  useEffect(() => {
    if (!animationData) return; // Ensure animation data is loaded
  
    let modifiedData = JSON.parse(JSON.stringify(animationData));
  
    if (useThemeColors !== 'original') {
      modifiedData.layers.forEach((layer, index) => {
        const color = useThemeColors === 'theme' ? themeColorPalette[index % themeColorPalette.length] : whiteColor;
        
        layer.shapes?.forEach(shape => { // Use optional chaining here
          shape.it?.forEach(item => { // And here
            if (item.ty === "fl" || item.ty === "st") {
              item.c.k = color;
            }
          });
        });
      });
    }
  
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: boolLoop,
      autoplay: true,
      animationData: modifiedData,
    });
  
    anim.setSpeed(speed); // Adjust the speed of the animation
    anim.addEventListener('complete', () => onComplete?.());
  
    return () => anim.destroy();
  }, [animationData, boolLoop, onComplete, useThemeColors, speed]);

  // Conditional styling based on displayMode
  const containerStyle = displayMode === 'floating' ? {
    position: "fixed",
    top: `${yPos}%`,
    left: `${xPos}%`,
    transform: "translate(-50%, -50%)",
    width: `${scale}%`,
    height: `${scale}%`,
    zIndex: zIndex,
    opacity: opacity / 100, // Convert percentage to decimal for CSS
  } : {
    display: 'inline-block', // For inline mode, display as inline-block
    width: `${width}px`, // Use width prop for dimensions in pixels
    height: `${height}px`, // Use height prop for dimensions in pixels
    zIndex: zIndex,
    opacity: opacity / 100, // Convert percentage to decimal for CSS
  };

  return <div ref={containerRef} style={containerStyle} />;
};

export default LottieAnimation;
