const initialState = {
  Numerology: {
    firstName: "",
    fullName: "",
    age: "",
    zodiac: "",
    dateOfBirth: "",
    step: "10",
    lifePath: "",
    expression: "",
    urge: "",
  },

  NumGameStepTracker: {
    currentStep: "10",
    SkipAI: false,
    DevMode: true,
  },

  isFetching: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_STEP":
      return {
        ...state,
        NumGameStepTracker: {
          ...state.NumGameStepTracker,
          currentStep: action.payload,
        },
      };

    case "STORE_VALUE":
      const { parentKey, key, value } = action.payload;
      return {
        ...state,
        [parentKey]: {
          ...state[parentKey],
          [key]: value,
        },
      };

    case "SET_IS_FETCHING":
      return {
        ...state,
        isFetching: action.payload,
      };

    default:
      return state;
  }
};

export default rootReducer;
