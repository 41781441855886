import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

export const CloudFunction = async (functionName, max_tokens, promptName, myData, skipAI) => {
  if (skipAI) {
    console.log('Debug Mode: Skipping OpenAI call.');
    return;
  }

  try {
    console.log('|CloudFunction| Calling AI...');
    const functionOpenAI = httpsCallable(functions, functionName);
    const result = await functionOpenAI({
      myData: myData,
      max_tokens: max_tokens,
      promptName: promptName,
    });

    //console.log('|CloudFunction service| OpenAI Result:', result.data.output);

    if (result && result.data) {
      console.log('|CloudFunction| OpenAI Response Received successfully.');

      return result.data.output;
    } else {
      console.error('Result or result data is null or undefined.');
      return null;
    }
  } catch (error) {
    console.error('Error calling OpenAI:', error);
    return null;
  }
};
