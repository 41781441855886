import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NumResultSpinAnimation from './NumResultSpinAnimation';

const LifePathDisplay = () => {
  return (
    <Fragment>
      <Grid
        container
        direction="column"
        className="lifePathDisplay-container"
        alignItems="center"
        justifyContent="center"
        spacing={2} // Adds spacing between grid items
      >
        <Grid item>
          <Typography variant="h1" className="text-gradient" sx={{ marginTop: 3 }}>
            Your Life Path Number
          </Typography>
        </Grid>
      </Grid>

      <NumResultSpinAnimation widgetNumber={1} />
    </Fragment>
  );
};

export default LifePathDisplay;
