import React, { Fragment } from 'react';
import '../styles/AnimGradient.css';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import NumResultSpinAnimation from './NumResultSpinAnimation';
import NumWidget from './NumWidget';
import '../styles/FeatheredImage.css';

const UrgeIntroDisplay = () => {
  // Grab the REDUX value 'NumGameStepTracker', 'currentAudioIndex', currentAudioIndex
  const currentAudioIndex = useSelector(state => state.storeValue.NumGameStepTracker.currentAudioIndex);
  const image01 = '/media/gfx/doorway.jpg';
  const image02 = '/media/gfx/glass-lens.jpg';

  return (
    <Fragment>
      {/* <NumWidget widgetNumber={3} /> */}
      <Grid container alignItems="center" justifyContent="center">
        {/* Title */}
        <Grid item>
          <Typography variant="h3" sx={{ paddingTop: 7, paddingBottom: 3 }}>
            {/* if currentAudioIndex < 5, display image01 */}
            {currentAudioIndex < 5 ? (
              <img src={image01} alt="Urge Number's Glass lens by elena " class="feathered-image" />
            ) : (
              <img src={image02} alt="Urge number to the numerology's Doorway by elena " class="feathered-image" />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UrgeIntroDisplay;
