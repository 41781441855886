import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import NumerologyGame from './components/NumerologyGame';
import GeneratePDFButton from './components/testGeneratePDF';
import TestGenerateAiTextBlocks from './components/TestGenerateAiTextBlocks';
import FunnelLanding from './components/FunnelLanding';
import FunnelCheckout from './components/FunnelCheckout';
import LottieAnimation from './components/testAnimation';
import UrgeInput from './components/UrgeInput';
import UrgeResult from './components/UrgeResult';
import FunnelIntro from './components/FunnelIntro';
import UrgeIntroDisplay from './components/UrgeIntroDisplay';
import ExpressionAnimation from './components/ExpressionAnimation';
import UrgeAnimExplainer from './components/UrgeAnimExplainer';
import './index.css';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import SendEmailComponent from './components/Email';
import SendEmailTemplate from './components/EmailTemplate';
import TestFirestore from './components/testDB';

function App() {
  console.log('-----------------------------------------------------');
  console.log('Running Environment: ' + process.env.REACT_APP_RUNNING_ENV);
  console.log('Base Domain: ' + process.env.REACT_APP_BASE_DOMAIN);
  console.log('Firebase Function Endpoint: ');
  console.log(process.env.REACT_APP_FIREBASE_FUNCTION_ENDPOINT);
  console.log('-----------------------------------------------------');

  return (
    <div>
      <div class="background-container"></div>
      {process.env.REACT_APP_RUNNING_ENV === 'development' && (
        <Routes>
          <Route path="/funnel" element={<FunnelLanding />} />
          <Route path="/checkout" element={<FunnelCheckout />} />
          <Route path="/lottie" element={<LottieAnimation />} />
          <Route path="/urge" element={<UrgeInput />} />
          <Route path="/AI" element={<UrgeResult />} />
          <Route path="/expAnim" element={<ExpressionAnimation />} />
          <Route path="/urgeAnim" element={<UrgeAnimExplainer />} />
          <Route path="/email" element={<SendEmailComponent />} />
          <Route path="/emailTemplate" element={<SendEmailTemplate />} />
          <Route path="db" element={<TestFirestore />} />
          <Route path="/funnel-intro" element={<FunnelIntro />} />
          <Route path="/generateAiBlocks" element={<TestGenerateAiTextBlocks />} />
          <Route path="/urge" element={<UrgeIntroDisplay />} />
        </Routes>
      )}
      <Routes>
        <Route path="/pdf" element={<GeneratePDFButton />} />
        <Route path="/" element={<NumerologyGame />} />
        <Route path="/thankyou" element={<PaymentSuccessPage />} />
      </Routes>
    </div>
  );
}

export default App;
