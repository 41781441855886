import React from 'react';

const LogoComponent = () => {

    return (
        <div class="LogoComponent-floating">
             <img src='/media/logo/elena-logo-01.png'></img>               
        </div>
    );
};

export default LogoComponent;
