// ** Wait for the user to input their marital status and email address
// ** Store the values in the Redux store
// ** Increase the journey step to 110

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsFetching, storeValue } from '../actions';
import { Box, Button, TextField, Typography, Select, Grid, InputLabel, MenuItem, FormControl } from '@mui/material';
import LottieAnimation from '../utils/CustomLottiePlayer';
import NumWidget from './NumWidget';
import { CloudFunction } from '../utils/CloudFunction';
import DotAnimation from './DotAnimation';
import { db, collection, addDoc } from '../firebase';

// MAIN FUNCTION
const UrgeInput = ({ updateCurrentStep }) => {
  const dispatch = useDispatch();
  const [marital, setMarital] = useState('');
  const [email, setEmail] = useState('');
  let myData = useSelector(state => state.storeValue);
  const skipAI = useSelector(state => state.storeValue.NumGameStepTracker.SkipAI);
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    setIsFetching(true);

    // Store MARITAL STATUS and EMAIL and increase the journey step
    dispatch(storeValue('Numerology', 'marital', marital));
    dispatch(storeValue('Numerology', 'email', email));

    // Update the myData object with the new values
    myData.Numerology.marital = marital;
    myData.Numerology.email = email;

    // Create a new user entry in the database
    console.log('Adding new user to the database:', JSON.stringify(myData.Numerology));
    const newUser = myData.Numerology;
    // Add a new document in collection "cities"
    try {
      const docRef = await addDoc(collection(db, 'users'), newUser);
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }

    // Call the CloudFunction
    const response = await CloudFunction('functionOpenAI', 80, 'urgePrompt', myData, skipAI);

    console.log('|urge Input| OpenAI Response:', response);

    if (response) {
      // Store the response in Redux if needed
      dispatch(storeValue('OpenAIResponses', 'urgePrompt', response));
    }

    updateCurrentStep(110);
  };

  const setDefaults = (setMarital, setEmail) => {
    setMarital('Single');
    setEmail('info@franky.com');
  };

  return (
    <>
      {/* <NumWidget widgetNumber={3} /> */}

      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <Box className="NumWelcome-MasterBox">
            <Box className="NumWelcome-Header">
              <LottieAnimation animationName="catFlying" scale={15} zIndex={0} opacity={100} boolLoop={true} displayMode="inline-block" useThemeColors="original" />
              <Typography variant="h4" sx={{ paddingY: 5 }}>
                Tell me where to send your personalized report
              </Typography>
            </Box>

            <Box className="NumWelcome-Form">
              <Box className="formWrapper">
                <form onSubmit={handleSubmit}>
                  <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
                    <FormControl fullWidth sx={{ flex: 1 }}>
                      <InputLabel id="month-label">Marital Status</InputLabel>
                      <Select variant="filled" label="Marital Status" value={marital} onChange={e => setMarital(e.target.value)} fullWidth={true}>
                        <MenuItem value="Single">Single</MenuItem>
                        <MenuItem value="Married">Married</MenuItem>
                        <MenuItem value="Engaged/Committed">Engaged/Committed</MenuItem>
                        <MenuItem value="Relationship">Relationship</MenuItem>
                        <MenuItem value="Divorced/Separated">Divorced/Separated</MenuItem>
                        <MenuItem value="Widowed">Widowed</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ flex: 2 }}>
                      <TextField variant="filled" label="Your Email Address" value={email} onChange={e => setEmail(e.target.value)} fullWidth={true} />
                    </FormControl>
                  </Box>

                  <Button sx={{ mt: 2 }} type="submit" variant="contained" color="primary" size="large" fullWidth>
                    CONTINUE WITH MY FREE READING
                  </Button>

                  {isFetching && <DotAnimation />}

                  <Box sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                    <Button variant="outlined" onClick={() => setDefaults(setMarital, setEmail)}>
                      &gt;_
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UrgeInput;
