import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import '../styles/NumWidget.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const NumWidget = ({ currentStep, firstTimeDisplay }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const lifePath = useSelector(state => state.storeValue.Numerology.lifePath);
  const expressionNumber = useSelector(state => state.storeValue.Numerology.expression);
  const urgeNumber = useSelector(state => state.storeValue.Numerology.urge);

  // Determine the grid container's width based on screen size
  const gridContainerWidth = isLargeScreen ? '50%' : isSmallScreen ? '90%' : '70%'; // Default to 70% for medium screens

  console.log('|Widget| Journey Step: ' + currentStep);

  return (
    <Fragment>
      <Grid
        container
        className={`lifePathDisplay-base ${firstTimeDisplay ? 'pulldown-animation' : ''}`}
        alignItems="center"
        justifyContent="center"
        style={{
          maxHeight: '5vh',
          position: 'fixed',

          left: '50%',
          transform: 'translateX(-50%)' /* Consider if this is needed initially */,
          width: gridContainerWidth,
          zIndex: 1000,
        }}
      >
        <Grid item xs={4} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <div className="num-widget-box">
            {' '}
            {/* Box wrapper added */}
            <div className="num-widget-sacred-animation">
              <img src="/media/gfx/sacred-geometry-001.png" alt="Sacred Geometry"></img>
              <div className="num-widget-number">{currentStep >= 30 ? lifePath : '?'}</div>
              <div className="num-widget-description">Life Path Number</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={4} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <div className="num-widget-box">
            <div className="num-widget-sacred-animation">
              <img src="/media/gfx/sacred-geometry-002.png" alt="Sacred Geometry 2"></img> {/* Assuming a different image for variety */}
              <div className="num-widget-number">{currentStep >= 70 ? expressionNumber : '?'}</div>
              <div className="num-widget-description">Expression Number</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={4} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <div className="num-widget-box">
            <div className="num-widget-sacred-animation">
              <img src="/media/gfx/sacred-geometry-003.png" alt="Sacred Geometry 3"></img> {/* Assuming a different image for variety */}
              <div className="num-widget-number">{currentStep >= 110 ? urgeNumber : '?'}</div>
              <div className="num-widget-description">Urge Number</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NumWidget;
