import React, { Fragment } from 'react';
import '../styles/AnimGradient.css';
import Grid from '@mui/material/Grid';
import NumWidget from './NumWidget';
import ExpressionAnimation from './ExpressionAnimation';
import '../styles/FeatheredImage.css';
import { useSelector } from 'react-redux';
import FloatingCharacters from './FloatingCharacters';

const ExpressionIntroDisplay = () => {
  // Grab the REDUX value 'NumGameStepTracker', 'currentAudioIndex', currentAudioIndex
  const currentAudioIndex = useSelector(state => state.storeValue.NumGameStepTracker.currentAudioIndex);
  const image01 = '/media/gfx/magic-web.webp';
  const image02 = '/media/gfx/treasure-purple.webp';

  return (
    <Fragment>
      {/* <FloatingCharacters /> */}
      {/* <NumWidget widgetNumber={3} /> */}
      <Grid container direction="column">
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '2rem',
          }}
        >
          <h1 className="text-gradient"> EXPRESSION</h1>
        </Grid>
        <Grid
          item
          style={{
            marginTop: '-2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {currentAudioIndex < 5 ? (
            <img src={image02} alt="swebs of the soul by elena " class="feathered-image" />
          ) : (
            <img src={image01} alt="treasure chest of wisdom by elena " class="feathered-image" />
          )}
        </Grid>
      </Grid>

      {/* <ExpressionAnimation /> */}
    </Fragment>
  );
};

export default ExpressionIntroDisplay;
