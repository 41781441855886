import React, { useState, useEffect } from 'react';
import { Box, Icon } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const DotAnimation = () => {
  const [dots, setDots] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      setDots(prevDots => [...prevDots, {}]);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      {dots.map((_, index) => (
        <Box
          key={index}
          display="inline-block"
          mx={0.25}
          style={{
            animation: 'dotGrow 2s ease-in-out',
            '@keyframes dotGrow': {
              '0%': {
                transform: 'scale(0)',
              },
              '100%': {
                transform: 'scale(0.5)',
              },
            },
          }}
        >
          <Icon component={CircleIcon} style={{ fontSize: '0.5rem' }} />
        </Box>
      ))}
    </Box>
  );
};

export default DotAnimation;
