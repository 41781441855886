import React from 'react';
import ReactDOM from 'react-dom/client'; //'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import theme from './theme.js';

import App from './App'; // Make sure this import path is correct
import reportWebVitals from './reportWebVitals';

// ** REDUX IMPORTS --------------------------------------------
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers'; // Import your root reducer
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';

// Create the Redux store, the second argument is for the Chrome pluggin for Redux
const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
