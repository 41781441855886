import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, TextField, FormControlLabel } from '@mui/material';
import '../styles/stripe.css';
import { getFunctions, httpsCallable } from 'firebase/functions';

const StripeCheckout = () => {
  const stripePromise = React.useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY), []);

  const [clientSecret, setClientSecret] = useState('');
  const [customerId, setCustomerId] = useState(null);
  const [customerCreated, setCustomerCreated] = useState(false);
  const [paymentIntentCreated, setPaymentIntentCreated] = useState(false);

  let name = useSelector(state => state.storeValue.Numerology.fullName);
  let email = useSelector(state => state.storeValue.Numerology.email);

  const createCustomer = async () => {
    if (customerCreated) {
      console.log('Customer ID already exists: ', customerId);
      return;
    }

    let userDetails;
    if (!name) {
      userDetails = {
        userEmail: 'email@gmail.com',
        userName: 'John Doe',
      };
    } else {
      userDetails = {
        userEmail: email,
        userName: name,
      };
    }

    const response = await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_ENDPOINT + '/createCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userDetails),
    });
    const data = await response.json();

    console.log('Customer', data.userName, '(', data.userEmail, ') created with ID:', data.customerId);
    setCustomerId(data.customerId);
    setCustomerCreated(true);
  };

  useEffect(() => {
    createCustomer();
  }, [customerCreated, customerId]);

  useEffect(() => {
    const createPaymentIntent = async () => {
      if (customerId && clientSecret === '' && !paymentIntentCreated) {
        const response = await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_ENDPOINT + '/createPaymentIntent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            items: [{ id: 'Personality Decoder', amount: 1400 }],
            customerId: customerId,
          }),
        });
        setPaymentIntentCreated(true);
        const data = await response.json();
        console.log('PaymentIntent Created (', data.clientSecret, ')');
        setClientSecret(data.clientSecret);
      }
    };

    createPaymentIntent();
  }, [customerId, clientSecret, paymentIntentCreated]);

  const appearance = {
    theme: 'night',
    labels: 'floating',
    variables: {
      colorPrimary: '#0f7dff',
      colorBackground: '#49447b',
    },
    rules: {
      '.Input': {
        boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.1), 3px 3px 3px rgba(0, 0, 0, 0.1)',
      },
      '.Tab': {
        boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.1), 3px 3px 3px rgba(0, 0, 0, 0.1)',
      },
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

function CheckoutForm() {
  console.log('$$$ CheckoutForm RENDERING $$$');
  const stripe = useStripe();
  const elements = useElements();
  const functions = getFunctions();
  const updateStripeCustomer = httpsCallable(functions, 'updateStripeCustomer');

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showBillingFields, setShowBillingFields] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleBillingInfoChange = e => {
    setShowBillingFields(e.target.checked);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log('CLICKED PAY BUTTON');
    if (!stripe || !elements) {
      console.log("Stripe.js hasn't loaded yet OR elements is not set.");
      return;
    }

    setIsLoading(true);

    const returnURL = process.env.REACT_APP_BASE_DOMAIN + '/checkout-complete';
    console.log('After payment return URL = ' + returnURL);
    console.log('Calling confirm Payment using customerId = ' + customerId);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/thankyou`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <Box className="formStripe">
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        <button disabled={isLoading || !stripe || !elements} id="submit" className="buttonStripe">
          <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : 'PAY NOW'}</span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </Box>
  );
}

export default StripeCheckout;
