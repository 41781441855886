import { React, useState } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import FunnelCheckout from './FunnelCheckout';

// Custom styles
const CustomButton = styled(Button)(({ theme }) => ({
  width: '80%',
  backgroundColor: '#f800ff',
  border: '2px solid white',
  fontSize: '28px',
  fontWeight: 600,
  padding: '20px 40px',
  marginBottom: '20px',
  color: 'white',
  '&:hover': {
    backgroundColor: '#f800ff',
    boxShadow: '0 0 20px #f800ff',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%', // Full width on small devices
  },
}));

// Custom styles
const PageContainer = styled(Box)({
  //   backgroundColor: "#1c1c3a",
  color: 'white',
  fontFamily: 'Montserrat, sans-serif',
  maxWidth: '1200px', // Set the max width to 1200px
  margin: '0 auto', // Center the content horizontally
  padding: '20px',
});

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center', // Centers content vertically
  height: '100%', // Full height of the container
  width: '100%', // Full width of the container
  textAlign: 'center', // Centers text horizontally

  // Breakpoint for small devices
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },

  // Breakpoint for medium devices
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },

  // Breakpoint for large devices
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const Spacer = styled('div')(({ height }) => ({
  height: height || '3rem', // Default height if not specified
}));

const FunnelLanding = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleGotoCheckout = () => {
    // The checkout is already loaded but invisible, this will make it visible
    setIsVisible(!isVisible);
  };

  return (
    <PageContainer>
      <Grid container style={{ display: isVisible ? 'none' : 'block' }}>
        {/* First Row */}
        <Grid item xs={12}>
          <CenteredBox>
            {/* <img
              src="/media/logo/elena-logo-01.png"
              alt="Logo"
              style={{ height: "40px" }}
            /> */}
            <Typography variant="h1" style={{ fontSize: '3.5rem', fontWeight: 600 }}>
              PERSONALITY DECODER
            </Typography>
            <Spacer height="1rem" />
            <Typography variant="h2" style={{ fontSize: '1.5rem' }}>
              What the 5 most important numbers in your chart reveal about your life, personality, and future
            </Typography>
            <Spacer height="1rem" />
          </CenteredBox>
        </Grid>

        {/* Second Row */}
        <Grid container item>
          {/* Product Image */}
          <Grid item xs={12} md={6}>
            <CenteredBox>
              <img
                src="/media/gfx/personality-decoder-book.png"
                alt="Product"
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  height: 'auto',
                  boxShadow: '20 20 30px #00000099',
                  // border: "1px solid gray",
                }}
              />
            </CenteredBox>
          </Grid>

          {/* Key Points and Button */}
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <CenteredBox>
              {/* Key Points */}
              <Typography variant="body1" sx={{ fontSize: 24, paddingY: 3 }}>
                <span style={{ fontSize: '24px' }}>✓</span> Completely Personalized Content
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 24, paddingY: 3 }}>
                <span style={{ fontSize: '24px' }}>✓</span> 36-Page PDF of Shocking Truths
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 24, paddingY: 3 }}>
                <span style={{ fontSize: '24px' }}>✓</span> Clarity and Direction
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 24, paddingY: 2 }}>
                <span style={{ fontSize: '24px' }}>✓</span> <span style={{ fontWeight: 'bold' }}>FREE BONUS:</span> Get VIP early access to Elena's AI psychic platform!
              </Typography>

              <Spacer />

              {/* Button */}
              <CustomButton onClick={handleGotoCheckout} fullWidth>
                YES, I WANT IT NOW!
              </CustomButton>
            </CenteredBox>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ display: isVisible ? 'block' : 'none' }}>
        <FunnelCheckout />
      </div>
    </PageContainer>
  );
};

export default FunnelLanding;
