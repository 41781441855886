import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../styles/NumerologyGame.scss';
import '../styles/gradient-component.css';

// ** NumerologyGame Components **
import NumGameWelcome from './NumGameWelcome';
import PlayRecordedSection from './PlayRecordedSection';
import LifePathIntroDisplay from './LifePathIntroDisplay';
import ExpressionIntroDisplay from './ExpressionIntroDisplay';
import ExpressionExplainDisplay from './ExpressionExplainDisplay';
import ExpressionResult from './ExpressionResult';
import ExpressionAnimation from './ExpressionAnimation';
import UrgeIntroDisplay from './UrgeIntroDisplay';
import UrgeInput from './UrgeInput';
import UrgeExplainDisplay from './UrgeExplainDisplay';
import LifePathDisplay from './LifePathDisplay';
import ExpressionInput from './ExpressionInput';
import UrgeResult from './UrgeResult';
import UrgeResultDisplay from './UrgeResultDisplay';
import UrgeAnimExplainer from './UrgeAnimExplainer';
import LogoComponent from './LogoComponent';
// import FunnelNumerology from "./FunnelNumerology";
import FunnelLanding from './FunnelLanding';
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import FunnelIntro from './FunnelIntro';
import DevAdmin from './DevAdmin';
import { ComponentName } from './DevAdmin';
import NumWidget from './NumWidget';

// Custom styles
const PageContainer = styled(Box)({
  color: 'white',
  maxWidth: '1200px', // Set the max width to 1200px
  margin: '0 auto', // Center the content horizontally
  padding: '0px 10px',
  overflow: 'hidden',
  height: '100vh', // Full height of the container
  width: '100%', // Full width of the container
  textAlign: 'center', // Centers text horizontally
});

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center', // Centers content vertically
  height: '100%', // Full height of the container
  width: '100%', // Full width of the container
  textAlign: 'center', // Centers text horizontally
}));

const NumerologyGame = () => {
  console.log('|| NUMEROLOGY GAME RENDER ||');
  const [currentStep, setCurrentStep] = useState(10);
  const lifePath = Number(300 + useSelector(state => Number(state.storeValue.Numerology.lifePath)));

  // JOURNEY STEP MANAGER THAT USES REACT useSTATE
  const updateCurrentStep = step => {
    setCurrentStep(step);
  };

  console.log('Current Journey Step: ' + currentStep + ' => ' + ComponentName[currentStep]);

  return (
    <PageContainer>
      {/* GRADIENT CIRCLE AMBIANT */}
      <div className="gradient-component"></div>

      {currentStep === 20 && <NumWidget firstTimeDisplay={true} currentStep={currentStep} />}
      {currentStep > 20 && currentStep <= 120 && <NumWidget firstTimeDisplay={false} currentStep={currentStep} />}

      <DevAdmin currentStep={currentStep} updateCurrentStep={updateCurrentStep} />
      {/* Funnel Section */}
      {/* <FunnelCheckout /> */}
      <div className={currentStep >= 130 ? 'funnel-true' : 'funnel-false'}>
        <FunnelLanding />
        {/* <FunnelCheckout /> */}
        {/* <CustomFunnelCheckout /> */}
        {/* <StripeEmbed /> */}
      </div>
      {/* Numerology Section */}
      {currentStep < 130 && (
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          {/* Top Row - Logo Component */}
          <Grid
            item
            style={{
              height: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <div className="num-logo">
              {" "}
              {currentStep < 120 && <LogoComponent />}{" "}
            </div> */}
          </Grid>
          {/* Second Row - Main Section */}
          <Grid
            item
            style={{
              height: '55%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CenteredBox>
              {currentStep === 10 && <NumGameWelcome currentStep={currentStep} updateCurrentStep={updateCurrentStep} />}
              {currentStep === 20 && <LifePathIntroDisplay currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={20} />}
              {currentStep === 30 && <LifePathDisplay currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={lifePath} />}
              {currentStep === 40 && <ExpressionIntroDisplay currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={40} />}
              {currentStep === 50 && <ExpressionInput currentStep={currentStep} updateCurrentStep={updateCurrentStep} />}
              {currentStep === 60 && <ExpressionExplainDisplay currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={60} />}
              {currentStep === 70 && <ExpressionResult currentStep={currentStep} updateCurrentStep={updateCurrentStep} />}
              {currentStep === 80 && <UrgeIntroDisplay currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={80} />}
              {currentStep === 90 && <UrgeInput currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={90} />}
              {currentStep === 100 && <UrgeAnimExplainer currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={100} />}
              {currentStep === 110 && <UrgeResultDisplay currentStep={currentStep} updateCurrentStep={updateCurrentStep} />}
              {currentStep === 110 && <UrgeResult currentStep={currentStep} updateCurrentStep={updateCurrentStep} />}
              {currentStep === 120 && <FunnelIntro currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={120} />}
              {currentStep === 125 && <FunnelLanding currentStep={currentStep} updateCurrentStep={updateCurrentStep} />}
            </CenteredBox>
          </Grid>
          {/* Third Row - PlayRecordedSection Components */}
          <Grid item style={{ height: '30%' }}>
            <div className="num-centered-component-area">
              {currentStep === 20 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={20} />}
              {currentStep === 30 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={lifePath} />}
              {currentStep === 40 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={40} />}
              {currentStep === 60 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={60} />}
              {currentStep === 80 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={80} />}
              {currentStep === 90 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={90} />}
              {currentStep === 100 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={100} />}
              {currentStep >= 120 && <PlayRecordedSection currentStep={currentStep} updateCurrentStep={updateCurrentStep} sectionNumber={120} />}
            </div>
          </Grid>
          {/* Last Row - Footer */}
          {/* <Grid item style={{ height: "5%" }}>
            <footer style={{ textAlign: "center", color: "gray" }}>
              Powered by ElenaMystic.com
            </footer>
          </Grid> */}
        </Grid>
      )}
    </PageContainer>
  );
};

export default NumerologyGame;
