import React, { Fragment } from 'react';
import '../styles/AnimGradient.css';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import LottieAnimation from '../utils/CustomLottiePlayer';
import NumWidget from './NumWidget';
import NumResultSpinAnimation from './NumResultSpinAnimation';
import ExpressionAnimation from './ExpressionAnimation';

const ExpressionExplainDisplay = updateCurrentStep => {
  console.log('>>> COMPONENT EXPRESSIONEXPLAINDISPLAY MOUNTED');

  return (
    <Fragment>
      {/* <NumWidget widgetNumber={3} /> */}
      <Grid container alignItems="center" justifyContent="center">
        {/* Title */}
        <Grid item>
          <Typography variant="h3" sx={{ paddingTop: 7, paddingBottom: 3 }}></Typography>
          <ExpressionAnimation />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ExpressionExplainDisplay;
