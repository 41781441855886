import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { storeValue } from '../actions';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

// Make an index for each number in the array to represent a component name
export const ComponentName = {
  10: 'NumGameWelcome',
  20: 'LifePathIntroDisplay',
  30: 'LifePathDisplay',
  40: 'ExpressionIntroDisplay + ExpressionAnimation',
  50: 'ExpressionInput',
  60: 'ExpressionExplainDisplay',
  70: 'ExpressionResult',
  80: 'UrgeIntroDisplay',
  90: 'UrgeInput',
  100: 'UrgeExplainDisplay',
  110: 'UrgeResultDisplay + UrgeResult',
  120: 'FunnelIntro',
  125: 'FunnelLanding',
  135: 'FunnelLanding',
};

const isLive = process.env.REACT_APP_RUNNING_ENV === 'production';

const DevAdmin = ({ storeValue, updateCurrentStep, currentStep }) => {
  // State to toggle the Skip AI button
  const [skipAiActive, setSkipAiActive] = useState(false);
  //const [currentStep, setCurrentStep] = useState(10);
  // Use useSelector to subscribe to a specific part of the Redux state
  const appStep = currentStep;
  const SkipAI = useSelector(state => state.storeValue.NumGameStepTracker.SkipAI);

  // Store GENDER and FULLNAME and increase the journey step
  if (useSelector(state => state.storeValue.Numerology.fullName) === '') {
    storeValue('Numerology', 'firstName', 'Doom');
    storeValue('Numerology', 'fullName', 'Dominic Vincent');
    storeValue('Numerology', 'email', 'info@domvincent.com');
    storeValue('Numerology', 'age', 44);
    storeValue('Numerology', 'gender', 'male');
    storeValue('Numerology', 'dateOfBirth', '4/10/1979');
    storeValue('Numerology', 'zodiac', 'Aries');
    storeValue('Numerology', 'expression', 1);
    storeValue('Numerology', 'urge', 11);
    storeValue('Numerology', 'lifePath', 22);
    storeValue('NumGameStepTracker', 'DevMode', true);
  }
  console.log('---------------------: DEV MODE is ON:--------------------------------');

  // Inline styling for the container
  const containerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#333',
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
  };

  // Inline styling for the buttons
  const buttonStyle = {
    backgroundColor: '#3a506b', // Desaturated, darker blue
    color: '#fff',
    '&:hover': {
      backgroundColor: '#293d56',
    },
  };

  // Function to handle clicks for each button
  const handleLifePathClick = () => {
    console.log('Life Path clicked');
    updateCurrentStep(20);
    //setCurrentStep(20);
    // Implement your navigation or logic here
  };

  const handleExpNumberClick = () => {
    console.log('Exp Number clicked');
    updateCurrentStep(40);
    //setCurrentStep(40);
    // Implement your navigation or logic here
  };
  const handleExpResultClick = () => {
    console.log('Exp Result clicked');
    updateCurrentStep(70);
    //setCurrentStep(40);
    // Implement your navigation or logic here
  };

  const handleUrgeNumberClick = () => {
    console.log('Urge Number clicked');
    updateCurrentStep(80);
    //setCurrentStep(80);
    // Implement your navigation or logic here
  };

  const handleFunnelClick = () => {
    console.log('Funnel clicked');
    updateCurrentStep(120);
    // Implement your navigation or logic here
  };

  const handleSkipAiClick = () => {
    if (SkipAI) {
      storeValue('NumGameStepTracker', 'SkipAI', false);
    } else {
      storeValue('NumGameStepTracker', 'SkipAI', true); // Toggle the active state
    }
    console.log('Skip AI toggled');
    // Implement your navigation or logic here
  };

  return (
    <div style={containerStyle}>
      <Typography variant="body2" paddingRight="2rem" color={isLive ? 'red' : 'gray'} fontWeight={isLive ? 'bold' : 'normal'}>
        ADMIN PANEL
      </Typography>
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button style={buttonStyle} onClick={handleLifePathClick}>
          Life Path
        </Button>
        <Button style={buttonStyle} onClick={handleExpNumberClick}>
          Exp Number
        </Button>
        <Button style={buttonStyle} onClick={handleExpResultClick}>
          Exp Result
        </Button>
        <Button style={buttonStyle} onClick={handleUrgeNumberClick}>
          Urge Number
        </Button>
        <Button style={buttonStyle} onClick={handleFunnelClick}>
          Funnel
        </Button>
        {/* Toggle color based on skipAiActive state */}
        <Button
          style={{
            ...buttonStyle,
            backgroundColor: SkipAI ? 'red' : buttonStyle.backgroundColor,
          }}
          onClick={handleSkipAiClick}
        >
          Skip AI
        </Button>
      </ButtonGroup>

      <Typography variant="body2" paddingLeft="2rem" color="gray">
        STEP: {appStep}
      </Typography>
      <Typography variant="body2" paddingLeft="2rem" color="gray">
        [{ComponentName[appStep]}]
      </Typography>
    </div>
  );
};

export default connect(null, { storeValue })(DevAdmin);
