import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

// Assume the CSS import is correct
import "../styles/ExpressionAnimation.css";

const ROWS = [
  ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
  ["A", "B", "C", "D", "E", "F", "G", "H", "I"],
  ["J", "K", "L", "M", "N", "O", "P", "Q", "R"],
  ["S", "T", "U", "V", "W", "X", "Y", "Z", " "],
];

const ExpressionAnimation = () => {
  const firstName = useSelector(
    (state) => state.storeValue.Numerology.firstName
  );
  const fullName = useSelector((state) => state.storeValue.Numerology.fullName);

  let name = fullName || firstName || "Dominic";
  const uppercaseName = name.toUpperCase();

  const [isAnimating, setIsAnimating] = useState(true);
  const [highlightSequenceDone, setHighlightSequenceDone] = useState(false);
  const [animatedCells, setAnimatedCells] = useState(new Set());
  const [highlightedLetters, setHighlightedLetters] = useState([]);
  const [highlightedValues, setHighlightedValues] = useState([]);

  // Adjusted useEffect for initial random highlighting with cell reset
  useEffect(() => {
    let animationCount = 0;
    const totalAnimationTime = 5000; // Total duration for the initial random highlighting
    const animationInterval = 800; // Interval between each random highlight set
    const animationDuration = 700; // Duration of each highlight

    const intervalId = setInterval(() => {
      let newAnimatedCells = new Set();
      while (newAnimatedCells.size < 15) {
        const randomCellIndex = Math.floor(Math.random() * ROWS.flat().length);
        newAnimatedCells.add(randomCellIndex);
      }
      setAnimatedCells(newAnimatedCells);

      setTimeout(() => {
        setAnimatedCells(new Set()); // Reset after each animation cycle
      }, animationDuration);

      animationCount += animationInterval;
      if (animationCount >= totalAnimationTime) {
        clearInterval(intervalId);
        setIsAnimating(false); // Proceed to the next animation phase
        // Additional step: ensure all cells are reset before starting the number highlight animation
        setTimeout(() => {
          setAnimatedCells(new Set());
          setHighlightSequenceDone(true); // Allows transition to the number highlight animation
          console.log("Initial random highlighting done");
        }, animationDuration); // Wait for the last reset to complete
      }
    }, animationInterval);

    return () => clearInterval(intervalId);
  }, []);

  // Sequential number highlighting
  useEffect(() => {
    if (!isAnimating && !highlightSequenceDone) {
      console.log("Starting number highlight animation");
      let delay = 4000;
      // Correctly ensure all numbers 1 to 9 are highlighted without reset in between
      for (let i = 0; i < ROWS[0].length; i++) {
        setTimeout(() => {
          // Add the index of the current number cell to the animatedCells set
          setAnimatedCells((prevCells) => new Set([...prevCells, i]));
        }, delay);
        delay += 100; // Increment delay for each number to ensure sequential highlighting
      }

      setTimeout(() => {
        // Wait for the last number to be highlighted before proceeding
        // Reset the highlighted cells state here if needed or proceed to the next animation phase
        // This timeout ensures all numbers are highlighted before any reset or next steps
        setHighlightSequenceDone(true); // Ready to start the name's letters animation
      }, delay);
    }
  }, [isAnimating]);

  useEffect(() => {
    if (highlightSequenceDone) {
      let delay = 10000; // Start after a delay to ensure a smooth transition from number highlighting
      uppercaseName.split("").forEach((letter, index) => {
        setTimeout(() => {
          // Find the row and column index of the letter in the grid
          ROWS.forEach((row, rowIndex) => {
            const colIndex = row.indexOf(letter);
            if (colIndex !== -1) {
              const cellIndex = rowIndex * 9 + colIndex;
              // Highlight the cell for the current letter
              setAnimatedCells(
                (prevCells) => new Set([...prevCells, cellIndex])
              );

              // Calculate the numerology value and update the state for displaying it
              const numerologyValueNumber = `${colIndex + 1}`; // +1 because columns are 1-indexed in display
              const numerologyValue = `${letter}`; // +1 because columns are 1-indexed in display

              setHighlightedLetters((prev) => [
                ...prev,
                numerologyValue +
                  (index < uppercaseName.length - 1 ? "   " : ""),
              ]);
              setHighlightedValues((prev) => [
                ...prev,
                numerologyValueNumber +
                  (index < uppercaseName.length - 1 ? " + " : ""),
              ]);
            }
          });
        }, delay);
        delay += 1000; // 1-second delay between each letter for highlighting and displaying numerology value
      });
    }
  }, [highlightSequenceDone, uppercaseName]);

  return (
    <Grid>
      <div className="pythagoreanGridContainer">
        {ROWS.flatMap((row, rowIndex) =>
          row.map((item, colIndex) => {
            const cellIndex = rowIndex * 9 + colIndex;
            const isHighlighted = animatedCells.has(cellIndex);
            const cellClass = `pythagoreanGridCell cell-${rowIndex}-${colIndex} ${
              isHighlighted ? "selected-cell" : ""
            }`;

            return (
              <div
                className={cellClass}
                key={`${rowIndex}-${colIndex}`}
                style={{
                  opacity: isHighlighted ? 1 : 0.5,
                  border: isHighlighted ? "2px solid white" : "1px solid white",
                  backgroundColor:
                    rowIndex === 0 && isHighlighted ? "orange" : "", // Highlight number row in yellow
                  transition:
                    "opacity 200ms ease-in-out, background-color 200ms ease-in-out",
                }}
              >
                {item}
              </div>
            );
          })
        )}
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {/* Displaying the numerology value of the name */}
        <Typography variant="h4">{highlightedLetters}</Typography>
        <Typography variant="h6">{highlightedValues}</Typography>
      </div>
    </Grid>
  );
};

export default ExpressionAnimation;
