import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { storeValue } from '../actions';
import LottieAnimation from '../utils/CustomLottiePlayer';
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import NumWidget from './NumWidget';
import { CloudFunction } from '../utils/CloudFunction';
import { calculateExpressionNumber, calculateUrgeNumber } from '../utils/NumerologyCalculation';
import DotAnimation from './DotAnimation';

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center', // Centers content vertically
  height: '100%', // Full height of the container
  width: '100%', // Full width of the container
  textAlign: 'center', // Centers text horizontally

  // Breakpoint for small devices
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1),
  },

  // Breakpoint for medium devices
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },

  // Breakpoint for large devices
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const Spacer = styled('div')(({ height }) => ({
  height: height || '3rem', // Default height if not specified
}));

const setDefaults = (setGender, setFullName) => {
  setGender('male');
  setFullName('Dominic Vincent');
};

const ExpressionInput = ({ updateCurrentStep }) => {
  const dispatch = useDispatch();
  const [gender, setGender] = useState('');
  const [fullName, setFullName] = useState('');
  let myData = useSelector(state => state.storeValue);
  const skipAI = useSelector(state => state.storeValue.NumGameStepTracker.SkipAI);
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsFetching(true);
    // Caluculate Expression Number
    const expNumber = calculateExpressionNumber(fullName);
    // Store the results in redux
    dispatch(storeValue('Numerology', 'expression', expNumber));

    // Calculate Urge Number
    const urgeNumber = calculateUrgeNumber(fullName);
    // Store the results in redux
    dispatch(storeValue('Numerology', 'urge', urgeNumber));

    // Store GENDER and FULLNAME and increase the journey step
    dispatch(storeValue('Numerology', 'gender', gender));
    dispatch(storeValue('Numerology', 'fullName', fullName));

    //Update the myData object with the new values
    myData.Numerology.expression = expNumber;
    myData.Numerology.urge = urgeNumber;
    myData.Numerology.gender = gender;
    myData.Numerology.fullName = fullName;

    // Call the CloudFunction
    const response = await CloudFunction('functionOpenAI', 80, 'expressionPrompt', myData, skipAI);

    console.log('|expression Input| OpenAI Response:', response);

    if (response) {
      // Store the response in Redux if needed
      dispatch(storeValue('OpenAIResponses', 'expressionPrompt', response));
    }

    updateCurrentStep(60);
  };

  return (
    <>
      {/* <NumWidget widgetNumber={3} /> */}
      <CenteredBox>
        <Spacer height="10rem" />
        <LottieAnimation animationName="catLaptop" scale={50} zIndex={0} opacity={100} boolLoop={true} displayMode="inline-block" useThemeColors="original" />
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem', lg: '3rem' },
          }}
        >
          To get your FREE personalized
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem', lg: '3rem' },
          }}
        >
          Expression number reading,
        </Typography>

        <Typography
          variant="h4"
          sx={{
            paddingBottom: 5,
            fontSize: { xs: '1.5rem', sm: '2rem', lg: '3rem' },
          }}
        >
          write your full name at birth:
        </Typography>

        <Box
          sx={{
            width: { md: '65%', sm: '100%' },
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  color="secondary"
                  focused
                  // variant="outlined"
                  labelId="gender-label"
                  label="Gender"
                  value={gender}
                  placeholder="Gender"
                  onChange={e => setGender(e.target.value)}
                  required
                  fullWidth
                >
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ flex: 3 }}>
                <TextField
                  color="secondary"
                  focused
                  variant="outlined"
                  label="Full Name at Birth"
                  value={fullName}
                  placeholder="Your Full Name at Birth"
                  onChange={e => setFullName(e.target.value)}
                  required
                  fullWidth
                  sx={{ flex: 2 }}
                />
              </FormControl>
            </Box>

            <Button sx={{ mt: 2 }} type="submit" variant="contained" color="secondary" size="large" endIcon={<SendIcon />} fullWidth>
              CONTINUE
            </Button>
          </form>
        </Box>
        <Spacer height="3rem" />
      </CenteredBox>
      {isFetching && <DotAnimation />}

      <Box sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        <Button variant="outlined" onClick={() => setDefaults(setGender, setFullName)}>
          &gt;_
        </Button>
      </Box>
    </>
  );
};

export default ExpressionInput;
