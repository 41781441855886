import React, { Fragment } from 'react';
import '../styles/AnimGradient.css';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import NumWidget from './NumWidget';

const FunnelIntro = () => {
  const name = useSelector(state => state.storeValue.Numerology.firstName);

  return (
    <Fragment>
      {/* <NumWidget widgetNumber={3} /> */}
      <Grid
        container
        direction="column"
        className="lifePathDisplay-container"
        alignItems="center"
        justifyContent="center"
        spacing={2} // Adds spacing between grid items
      >
        {/* Title */}
        <Grid item>
          <Typography variant="h1" className="text-gradient">
            ...SO MUCH MORE
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FunnelIntro;
