// src/@core/theme/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // Your theme overrides
  palette: { mode: "dark" },
  components: {
    // Example: Override MUI TextField
    MuiTextField: {
      styleOverrides: {
        root: {
          // Styles for the root element of TextField
        },
      },
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    fontSize: 14,
  },
  // Add more customizations as needed
});

export default theme;


