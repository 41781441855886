import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import NumWidget from "./NumWidget";

// Assume the CSS import is correct
import "../styles/UrgeAnimation.css";

const UrgeAnimExplainer = () => {
  const fullName = "Dominic Vincent".toUpperCase(); // Display the name in uppercase

  // Array of vowels and their numerical values for Calculation
  const vowelValues = {
    A: 1,
    E: 5,
    I: 9,
    O: 6,
    U: 3,
  };

  // Array of vowels and their numerical values for JSX display
  const vowelsAndValues = [
    { vowel: "A", value: 1 },
    { vowel: "E", value: 5 },
    { vowel: "I", value: 9 },
    { vowel: "O", value: 6 },
    { vowel: "U", value: 3 },
    { vowel: "Y", value: 7 },
  ];

  const calculateSoulUrgeNumber = (name) => {
    let vowels = name.match(/[aeiou]/gi) || [];
    let sum = vowels.reduce(
      (acc, vowel) => acc + vowelValues[vowel.toUpperCase()],
      0
    );

    // Reduce the sum to a single digit, unless it's a master number
    const reduceSum = (number) => {
      if (number === 11 || number === 22) {
        return number;
      }
      let reducedNumber = number
        .toString()
        .split("")
        .reduce((acc, digit) => acc + parseInt(digit, 10), 0);
      if (reducedNumber > 9 && reducedNumber !== 11 && reducedNumber !== 22) {
        return reduceSum(reducedNumber); // Recursively reduce the number
      }
      return reducedNumber;
    };

    return reduceSum(sum);
  };

  // Prepare vowel-related displays
  const vowelsInName = fullName.match(/[aeiou]/gi) || [];
  const vowelsDisplay = vowelsInName.join("").toUpperCase();
  const numericalEquivalents = vowelsInName
    .map((vowel) => vowelValues[vowel.toUpperCase()])
    .join(" + ");
  const numericalSum = vowelsInName.reduce(
    (acc, vowel) => acc + vowelValues[vowel.toUpperCase()],
    0
  );
  const soulUrgeNumber = calculateSoulUrgeNumber(fullName);
  const reductionSteps =
    numericalSum > 9 && soulUrgeNumber !== 11
      ? numericalSum.toString().split("").join(" + ")
      : null;

  return (
    <>
      <NumWidget widgetNumber={3} />

      <Box
        sx={{
          backgroundColor: "transparent",
          color: "white",
          fontFamily: "Arial, sans-serif",
          textAlign: "center",
          padding: 2,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          SOUL URGE NUMBER
        </Typography>
        <Typography variant="h5" gutterBottom>
          (Heart’s Desire Number)
        </Typography>
        <Typography variant="body1" gutterBottom>
          In numerology, your Soul Urge Number represents your life purpose, and
          reveals your destiny, what matters most to you, and how you inspire
          others.
        </Typography>
        <Typography variant="h6" sx={{ mt: 3 }}>
          How To Calculate Yours:
        </Typography>

        <Grid>
          <div className="urgeGridContainer">
            {vowelsAndValues.map((item, index) => (
              <div
                className={`urgeGridCell cell-0-${index}`}
                key={`vowel-${index}`}
                style={{
                  opacity: 1,
                  border: "1px solid white",
                  backgroundColor: "", // You can specify the color or leave it empty
                  transition:
                    "opacity 200ms ease-in-out, background-color 200ms ease-in-out",
                }}
              >
                {item.vowel}
              </div>
            ))}
            {vowelsAndValues.map((item, index) => (
              <div
                className={`urgeGridCell cell-1-${index}`}
                key={`value-${index}`}
                style={{
                  opacity: 1,
                  border: "1px solid white",
                  backgroundColor: "", // You can specify the color or leave it empty
                  transition:
                    "opacity 200ms ease-in-out, background-color 200ms ease-in-out",
                }}
              >
                {item.value}
              </div>
            ))}
          </div>
        </Grid>

        <Box>
          <Typography variant="body1">Your full birth name:</Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
            {fullName}
          </Typography>
          <Typography variant="body1">
            Assign each vowel its numerical value according to numerology:
          </Typography>
          <Grid container justifyContent="center" sx={{ my: 1 }}>
            {Object.entries(vowelValues).map(([vowel, value]) => (
              <Grid item key={vowel}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mx: 1 }}>
                  {vowel}={value}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Typography variant="body1" gutterBottom>
            {vowelsDisplay} = {numericalEquivalents} = {numericalSum}
          </Typography>
          {reductionSteps && (
            <Typography variant="body1" gutterBottom>
              {reductionSteps} = {soulUrgeNumber}
            </Typography>
          )}
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mt: 3 }}
          >
            Your Soul Urge Number is: {soulUrgeNumber}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default UrgeAnimExplainer;
