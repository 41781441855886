import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import TextPlayer from './TextPlayer';

const UrgeResult = ({ currentStep, updateCurrentStep }) => {
  const myData = useSelector(state => state.storeValue);
  const [openAIText, setOpenAIText] = useState('');

  useEffect(() => {
    setOpenAIText(myData.OpenAIResponses.urgePrompt);
  }, [myData.OpenAIResponses.urgePrompt]);

  return openAIText ? <TextPlayer text={openAIText} currentStep={currentStep} updateCurrentStep={updateCurrentStep} /> : <center>Cross analysing your Soul Urge...</center>;
};

export default UrgeResult;
