import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { storeValue } from '../actions';
import LottieAnimation from '../utils/CustomLottiePlayer';

import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NumWidget from './NumWidget';
import NumResultSpinAnimation from './NumResultSpinAnimation';

const UrgeResultDisplay = () => {
  return (
    <Fragment>
      {/* <NumWidget widgetNumber={3} /> */}
      <Grid container alignItems="center" justifyContent="center">
        {/* Title */}
        <Grid item>
          <Typography variant="h3" sx={{ paddingTop: 30, paddingBottom: 3 }}>
            Your urge number is:
          </Typography>
        </Grid>
      </Grid>

      <NumResultSpinAnimation widgetNumber={3} />
    </Fragment>
  );
};

export default UrgeResultDisplay;
