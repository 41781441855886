import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { storeValue } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

import LottieAnimation from '../utils/CustomLottiePlayer';
import '../styles/gradient-component.css';

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoComponent from './LogoComponent';

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center', // Centers content vertically
  height: '100%', // Full height of the container
  width: '100%', // Full width of the container
  textAlign: 'center', // Centers text horizontally
}));

const NumGameWelcome = ({ updateCurrentStep }) => {
  const dispatch = useDispatch();
  const [playAnimation, setPlayAnimation] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [birthDate, setBirthDate] = useState({ day: '', month: '', year: '' });
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const boolMakeAPICalls = false;

  const getYears = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 1920; i--) {
      years.push(i);
    }

    return years;
  };

  function calculateLifePathNumber(birthDate) {
    // Helper function to reduce a number to a single digit or Master Number
    function reduceToSingleDigit(num) {
      while (num > 9 && num !== 11 && num !== 22 && num !== 33) {
        num = num
          .toString()
          .split('')
          .reduce((acc, digit) => acc + parseInt(digit, 10), 0);
      }
      return num;
    }

    // Since the month, day, and year are strings, parse them as integers
    const day = parseInt(birthDate.day, 10);
    const month = parseInt(birthDate.month, 10);
    const year = parseInt(birthDate.year, 10);

    // Reduce the day, month, and year to single digits or Master Numbers
    const reducedDay = reduceToSingleDigit(day);
    const reducedMonth = reduceToSingleDigit(month);
    const reducedYear = reduceToSingleDigit(year);
    const digitSum = day + month + year;
    //console.log(`Day: ${day} Month: ${month} Year: ${year} Sum: ${digitSum}`);

    // Sum the reduced numbers and reduce again to get the Life Path Number
    const lifePathNumber = reduceToSingleDigit(digitSum);
    //console.log(`Life Path Number: ${lifePathNumber}`);

    return lifePathNumber;
  }

  //MP3 Player loop
  const playAudioFile = (filePath, loop = false) => {
    return new Promise(resolve => {
      const audio = new Audio();
      audio.src = filePath;
      audio.loop = loop;
      audio.volume = 0.5;
      audio.addEventListener('ended', resolve);
      audio.play().catch(error => console.error('Error playing audio:', error));
    });
  };

  function calculateAge(birthdate) {
    const birthdateObj = new Date(birthdate);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthdateObj.getFullYear();

    // Adjust age if the birthdate has not occurred yet this year
    const currentMonth = currentDate.getMonth();
    const birthMonth = birthdateObj.getMonth();
    const currentDay = currentDate.getDate();
    const birthDay = birthdateObj.getDate();

    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }

    return age;
  }

  function getZodiacSign(birthdate) {
    const date = new Date(birthdate);
    const month = date.getMonth() + 1; // Adding 1 because month index is zero-based
    const day = date.getDate();

    if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
      return 'Aquarius';
    } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
      return 'Pisces';
    } else if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
      return 'Aries';
    } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
      return 'Taurus';
    } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
      return 'Gemini';
    } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
      return 'Cancer';
    } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
      return 'Leo';
    } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
      return 'Virgo';
    } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
      return 'Libra';
    } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
      return 'Scorpio';
    } else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
      return 'Sagittarius';
    } else {
      return 'Capricorn';
    }
  }

  const setDefaults = () => {
    setFirstName('Dom');
    setBirthDate({ month: 4, day: 10, year: 1979 });
  };

  const handleAnimationComplete = () => {
    // ANIMATION STATE
    setPlayAnimation(false); // Update state to indicate animation has played

    const sBirthDate = `${birthDate.month}/${birthDate.day}/${birthDate.year}`;
    const lifePath = calculateLifePathNumber(birthDate);
    dispatch(storeValue('Numerology', 'firstName', firstName));
    dispatch(storeValue('Numerology', 'dateOfBirth', sBirthDate));
    dispatch(storeValue('Numerology', 'lifePath', lifePath));

    // Calculate age and store in Redux
    const age = calculateAge(sBirthDate);
    dispatch(storeValue('Numerology', 'age', age));

    // Calculate Zodiac Sign and store in Redux
    const zodiacSign = getZodiacSign(sBirthDate);
    dispatch(storeValue('Numerology', 'zodiac', zodiacSign));

    //console.log(`Life Path Number = ${lifePath}`);
    //console.log(`API Switch = ${boolMakeAPICalls}`);
    //console.log(`sBirthDate = ${sBirthDate}`);

    // Increase step to 30
    updateCurrentStep(20);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    // Start playing the MP3 file AUDIO AMBIANCE
    playAudioFile('/media/UnconditionalLove.mp3', true);
    // Trigger the animation to play
    setPlayAnimation(true);
  };

  return (
    <>
      <LogoComponent />

      {/* LOTTIE ANIMATION */}
      <div>
        {playAnimation && (
          <LottieAnimation
            animationName="animCongratsCentered" // Adjust based on your naming and files
            xPos={50}
            yPos={15}
            scale={50} // Adjust based on your preference
            zIndex={-5000} // Ensure it's visible as needed
            opacity={100}
            boolLoop={false}
            useThemeColors="theme"
            onComplete={handleAnimationComplete}
          />
        )}
      </div>

      <Typography variant="h1" className="font-intro1" sx={{ fontWeight: 500 }}>
        UNLOCK THE SECRETS
      </Typography>
      <Typography variant="h1" className="font-intro2" sx={{ fontWeight: 500 }}>
        OF YOUR NAME AND BIRTH DATE
      </Typography>
      <Box
        sx={{
          width: { lg: '55%', md: '65%', sm: '85%', xs: '90%' },
          margin: 'auto',
          maxWidth: '1200px', // Adjust as needed
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField variant="filled" label="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} required fullWidth margin="normal" />

          <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 3 }} className="birthdate-inputs">
            <FormControl fullWidth>
              <InputLabel id="month-label">Month</InputLabel>
              <Select
                variant="filled"
                label="month-label"
                value={birthDate.month}
                onChange={e =>
                  setBirthDate({
                    ...birthDate,
                    month: parseInt(e.target.value, 10),
                  })
                }
                required
              >
                {monthNames.map((month, i) => (
                  <MenuItem key={i} value={i + 1}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="day-label">Day</InputLabel>
              <Select
                variant="filled"
                labelId="day-label"
                value={birthDate.day}
                onChange={e =>
                  setBirthDate({
                    ...birthDate,
                    day: parseInt(e.target.value, 10),
                  })
                }
                required
              >
                {[...Array(31)].map((_, i) => (
                  <MenuItem key={i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="year-label">Year</InputLabel>
              <Select
                variant="filled"
                labelId="year-label"
                value={birthDate.year}
                onChange={e =>
                  setBirthDate({
                    ...birthDate,
                    year: parseInt(e.target.value, 10),
                  })
                }
                required
              >
                <MenuItem value="">Year</MenuItem>
                {getYears().map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
            LET'S GO!
          </Button>
        </form>
      </Box>

      <Box sx={{ position: 'fixed', bottom: '15px', right: '20px' }}>
        <Button variant="outlined" onClick={() => setDefaults()}>
          &gt;_
        </Button>
      </Box>
    </>
  );
}; // End of Main Function

export default NumGameWelcome;
