import React, { useEffect, useState, useRef } from 'react';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { storeValue, updateStep } from '../actions';
import LinearProgress from '@mui/material/LinearProgress';

//**--------------------------------------------------
//** Takes a text input, splits it into chunks,
//** converts it to audio using Google TTS,
//** and plays each chunk's audio sequentially
//** while displaying the current chunk's text on the screen
//**--------------------------------------------------

const functionGoogleTTS = httpsCallable(functions, 'functionGoogleTTS');

// Main component
/**
 * TextPlayer component
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to be played.
 * @returns {JSX.Element} The TextPlayer component.
 */
const TextPlayer = ({ text = ' ', currentStep, updateCurrentStep }) => {
  const [chunks, setChunks] = useState([]);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const previousText = useRef(null);
  const audioRef = useRef();
  const dispatch = useDispatch();
  //const currentStep = useSelector(state => state.storeValue.NumGameStepTracker.currentStep);

  useEffect(() => {
    if (isProcessing) {
      console.log('TEXT PLAYER >> Already processing text.');
      return;
    }
    setIsProcessing(true);
    if (previousText.current !== text) {
      console.log('|TEXT PLAYER| Starting processing text to audio...');

      // Create chunks from the text
      //console.log('Creating chunks...');
      const sentences = text.match(/[^.!?]+[.!?]+/g) || [text];
      const chunksTemp = [];
      let chunk = sentences[0].trim();
      let wordCount = chunk.split(' ').length;

      for (let i = 1; i < sentences.length; i++) {
        const sentence = sentences[i].trim();
        const sentenceWordCount = sentence.split(' ').length;

        if (wordCount < 4 && wordCount + sentenceWordCount < 5) {
          chunk = `${chunk} ${sentence}`;
          wordCount += sentenceWordCount;
        } else {
          chunksTemp.push(chunk);
          chunk = sentence;
          wordCount = sentenceWordCount;
        }
      }

      if (chunk) {
        chunksTemp.push(chunk);
      }

      //console.log('Chunks created: ', chunksTemp);
      setChunks(chunksTemp);

      // Get audioURL for each chunk
      //console.log('Retrieving audio URLs for each chunk...');

      // Loop over the chunks and simulate sending them to functionGoogleTTS
      chunksTemp.forEach((chunk, index) => {
        console.log(`> Sending chunk ${index + 1} to functionGoogleTTS: ${chunk}`);
      });

      previousText.current = text;

      const audioPromises = chunksTemp.map(async chunk => {
        try {
          const response = await functionGoogleTTS({ text: chunk });

          // Convert base64 audio content into Blob
          const binaryString = window.atob(response.data.audioContent);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes.buffer], { type: 'audio/mpeg' });
          const url = URL.createObjectURL(blob);
          return url;
        } catch (error) {
          console.error(`Error fetching audio for chunk ${chunk}:`, error);
          return '';
        }
      });

      // Play audio chunks sequentially
      //console.log('TEXT PLAYER >> Begin playing audio...');
      setLoading(false);

      const playAudio = async () => {
        for (let i = 0; i < audioPromises.length; i++) {
          const audioURL = await audioPromises[i];
          if (audioURL) {
            const audioLenght = audioPromises.length;
            console.log(`Playing Audio Chunk ${i + 1}/${audioLenght}`);
            setCurrentChunkIndex(i);
            if (audioRef.current) {
              audioRef.current.pause();
            }
            audioRef.current = new Audio(audioURL);
            audioRef.current.play();
            await new Promise(resolve => {
              audioRef.current.addEventListener('ended', resolve);
            });
            setCurrentChunkIndex(i);

            //console.log(`Finished playing chunk ${i + 1}`);
            //////////////////////////////////////////////////////
            // TEST IF THE CURRENT STEP (currentStep) is equal to 110. If so, now that we are done with the AI speech,
            // we can go to the upsell by changing the step to 120
            //console.log('Current Step: ' + currentStep);
            if (i + 1 === audioLenght) {
              if (currentStep === 110) {
                //new way of updating game step
                updateCurrentStep(120);
                //old way of updating game step
                dispatch(updateStep(120));
              }
              if (currentStep === 70) {
                //new way of updating game step
                updateCurrentStep(80);
                //old way of updating game step
                dispatch(updateStep(80));
              }
            }
          }
        }
      };

      playAudio();
      setIsProcessing(false);
    }
  }, [text]);

  return (
    <div>
      <Box>
        <h1>{loading ? 'Creating chunks array...' : chunks[currentChunkIndex]}</h1>
        {/* <p>Progress: {Math.round(((currentChunkIndex + 1) / chunks.length) * 100)}%</p> */}

        {/* <LinearProgress variant="determinate" value={Math.round(((currentChunkIndex + 1) / chunks.length) * 100)} />{' '} */}
      </Box>
    </div>
  );
};

export default TextPlayer;
