import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import '../styles/NumResultSpinAnimation.css';

const NumResultSpinAnimation = ({ widgetNumber }) => {
  const lifePath = useSelector(state => state.storeValue.Numerology.lifePath);
  const expressionNumber = useSelector(state => state.storeValue.Numerology.expression);
  const urgeNumber = useSelector(state => state.storeValue.Numerology.urge);

  // Function to select the right number and description based on widgetNumber
  const getNumberAndDescription = () => {
    switch (widgetNumber) {
      case 1:
        return {
          number: lifePath,
          imgSrc: '/media/gfx/sacred-geometry-001.png',
          description: 'Life Path Number',
        };
      case 2:
        return {
          number: expressionNumber,
          imgSrc: '/media/gfx/sacred-geometry-002.png',
          description: 'Expression Number',
        };
      case 3:
        return {
          number: urgeNumber,
          imgSrc: '/media/gfx/sacred-geometry-003.png',
          description: 'Urge Number',
        };
      default:
        return { number: '', imgSrc: '', description: '' }; // Default case if needed
    }
  };

  const { number, imgSrc, description } = getNumberAndDescription();

  return (
    // <Grid
    //   container
    //   className="num-result-spin-animation-container"
    //   alignItems="center"
    //   justifyContent="center"
    //   style={{ height: "100%", width: "100%" }} // Adjusted to fill the parent container
    // >
    <Grid item display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <div className="num-result-spin-animation-sacred-animation">
        <img src={imgSrc} alt="Sacred Geometry"></img>
        <div className="num-result-spin-animation-number">{number}</div>
        {/* <div className="num-result-spin-animation-description">
            {description}
          </div> */}
      </div>
    </Grid>
    // </Grid>
  );
};

export default NumResultSpinAnimation;
