import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextPlayer from './TextPlayer';
import { functions } from '../firebase'; // assuming this is the location of your firebase initialization file
import { httpsCallable } from 'firebase/functions';
import { storeValue } from '../actions';
import NumWidget from './NumWidget';
import NumResultSpinAnimation from './NumResultSpinAnimation';
import { Typography, Grid } from '@mui/material';

// The Pythagorean mapping of letters to numbers
const PYTHAGOREAN_CHART = {
  a: 1,
  b: 2,
  c: 3,
  d: 4,
  e: 5,
  f: 6,
  g: 7,
  h: 8,
  i: 9,
  j: 1,
  k: 2,
  l: 3,
  m: 4,
  n: 5,
  o: 6,
  p: 7,
  q: 8,
  r: 9,
  s: 1,
  t: 2,
  u: 3,
  v: 4,
  w: 5,
  x: 6,
  y: 7,
  z: 8,
};

const functionOpenAI = httpsCallable(functions, 'functionOpenAI');

const ExpressionResult = ({ currentStep, updateCurrentStep }) => {
  console.log('>>> COMPONENT EXPRESSIONRESULT MOUNTED');
  // **--- Call OpenAI if FALSE or skip if TRUE ---
  const [fetchinAI, setFetchinAI] = useState(false);
  const skipAI = useSelector(state => state.storeValue.NumGameStepTracker.SkipAI);

  const dispatch = useDispatch();

  const myData = useSelector(state => state.storeValue);
  const [openAIText, setOpenAIText] = useState('');
  const fullName = useSelector(state => state.storeValue.Numerology.fullName);

  useEffect(() => {
    setOpenAIText(myData.OpenAIResponses.expressionPrompt);
  }, [myData.OpenAIResponses.expressionPrompt]);

  // EXPRESSION NUMBER CALCULATION ///////////////////////////////////////////////////////
  function calculateExpressionNumber(fullName) {
    const lowerCaseName = fullName.toLowerCase();
    const firstName = lowerCaseName.split(' ')[0];
    const lastName = lowerCaseName.split(' ')[1];

    // Map each letter in the name to its corresponding number,
    // and reduce to a sum
    let sum = 0;
    for (let i = 0; i < lowerCaseName.length; i++) {
      if (lowerCaseName[i] !== ' ') {
        // skip spaces
        sum += PYTHAGOREAN_CHART[lowerCaseName[i]];
      }
    }
    console.log('exp number sum: ', sum);

    // Reduce the sum to a single digit number, but don't reduce
    // master numbers 11 and 22
    if (sum === 11 || sum === 22) {
      return sum;
    }
    while (sum > 9) {
      sum = String(sum)
        .split('')
        .reduce((acc, curr) => acc + Number(curr), 0);
    }
    console.log('exp number sum after reduction: ', sum);

    return sum;
  }

  return (
    <Fragment>
      {/* <NumWidget widgetNumber={3} /> */}
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="h3" sx={{ paddingTop: 25, paddingBottom: 3 }}>
            Your expression number is:
          </Typography>
        </Grid>
      </Grid>
      <NumResultSpinAnimation widgetNumber={2} />

      <Grid item style={{ height: '30%' }}>
        <div className="num-centered-component-area">
          {openAIText && <TextPlayer text={openAIText} currentStep={currentStep} updateCurrentStep={updateCurrentStep} />} {!openAIText && <p>Calculating your Expression Number results...</p>}
        </div>
      </Grid>
    </Fragment>
  );
};

export default ExpressionResult;
