import React, { useState, useEffect } from 'react';
import axios from 'axios'; // using axios for HTTP requests
import { Grid, TextField, Button, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import LottieAnimation from '../utils/CustomLottiePlayer';

import SendIcon from '@mui/icons-material/Send';
import { calculateLifePathNumber, calculateExpressionNumber, calculateUrgeNumber } from '../utils/NumerologyCalculation';

const GeneratePDFButton = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [lifePath, setLifePath] = useState('');
  const [expNumber, setExpNumber] = useState('');
  const [soulUrge, setSoulUrge] = useState('');

  useEffect(() => {
    if (name) {
      setExpNumber(calculateExpressionNumber(name));
      setSoulUrge(calculateUrgeNumber(name));
    }
  }, [name]);

  useEffect(() => {
    if (dob.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      const [month, day, year] = dob.split('/');
      const dobObject = { month, day, year };
      setLifePath(calculateLifePathNumber(dobObject));
    }
  }, [dob]);

  const handleGeneratePDF = async () => {
    const firstName = name.split(' ')[0];

    //Create User Data Object
    const userData = {
      userData: `Name: ${firstName}
        Date of Birth: ${dob}
        Life Path Number: ${lifePath}
        Expression Number: ${expNumber}
        Soul Urge Number: ${soulUrge}
    `,
    };

    console.log('userData: ', userData);

    // try {
    //   setLoading(true);
    //   console.log("Test genereate PDF button clicked!");

    //   //Set the cloud function to call
    //   const cloudFunction =
    //     process.env.REACT_APP_FIREBASE_FUNCTION_ENDPOINT +
    //     "/" +
    //     "generateNumerologyPDF";

    //   console.log("cloudFunction: " + cloudFunction);

    //   // Make an HTTP POST request
    //   const response = await axios.post(cloudFunction, userData);

    //   console.log("PDF download link:", response.data);
    //   setUrl(response.data.downloadLink);

    //   setLoading(false);
    //   // Handle the PDF generation result
    // } catch (error) {
    //   console.error("Error generating PDF:", error);
    // }
  };

  const handleDobChange = event => {
    const { value } = event.target;
    let finalValue = value.replace(/[^\d]/g, ''); // Remove non-digits
    // Automatically add slashes
    if (finalValue.length > 2 && finalValue.length <= 4) {
      finalValue = finalValue.slice(0, 2) + '/' + finalValue.slice(2);
    } else if (finalValue.length > 4) {
      finalValue = finalValue.slice(0, 2) + '/' + finalValue.slice(2, 4) + '/' + finalValue.slice(4, 8);
    }
    setDob(finalValue); // Update state
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <LottieAnimation animationName="catHidding" scale={50} zIndex={0} opacity={100} boolLoop={true} displayMode="inline-block" useThemeColors="original" />
      {loading && <CircularProgress />}
      <br></br>
      <br></br>
      <br></br>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <TextField label="Name" variant="outlined" value={name} onChange={e => setName(e.target.value)} />
        </Grid>
        <Grid item>
          <TextField label="Date of Birth" variant="outlined" placeholder="MM/DD/YYYY" value={dob} onChange={handleDobChange} inputProps={{ maxLength: 10 }} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleGeneratePDF} disabled={loading} size="large" endIcon={<SendIcon />}>
            {loading ? 'One Moment...' : 'Generate PDF'}
          </Button>
        </Grid>
      </Grid>
      {/* Display Numerology Results */}
      <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          {lifePath && !url && (
            <Typography variant="h6" align="center">
              Life Path Number: {lifePath}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {expNumber && !url && (
            <Typography variant="h6" align="center">
              Expression Number: {expNumber}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {soulUrge && !url && (
            <Typography variant="h6" align="center">
              Soul Urge Number: {soulUrge}
            </Typography>
          )}
        </Grid>
      </Grid>

      {url && <iframe src={url} title="PDF Preview" width="100%" height="1150px" />}
    </div>
  );
};

export default GeneratePDFButton;
