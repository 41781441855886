import React from 'react';
import '../styles/AnimGradient.css';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../styles/NumResultSpinAnimation.css';

const LifePathIntroDisplay = () => {
  const name = useSelector(state => state.storeValue.Numerology.firstName);

  return (
    <>
      <Grid
        container
        direction="column"
        className="lifePathDisplay-container"
        alignItems="center"
        justifyContent="center"
        spacing={2} // Adds spacing between grid items
      >
        <Grid item>
          <Typography variant="h1" className="text-gradient" sx={{ marginTop: 3 }}>
            NUMEROLOGY
          </Typography>
        </Grid>

        <Grid item display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <div className="num-result-spin-animation-sacred-animation">
            <img src="/media/gfx/sacred-geometry-001.png" alt="Sacred Geometry"></img>
            <div className="first-name">{name}</div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default LifePathIntroDisplay;
