import React from 'react';
import '../styles/AnimGradient.css';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../styles/NumResultSpinAnimation.css';
import LottieAnimation from '../utils/CustomLottiePlayer';

const PaymentSuccessPage = () => {
  const name = useSelector(state => state.storeValue.Numerology.firstName) || 'Stranger';
  const email = useSelector(state => state.storeValue.Numerology.email) || 'you@gmail.com';

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
      <Grid container direction="column" className="lifePathDisplay-container" alignItems="center" justifyContent="center" spacing={2}>
        {/* LOTTIE ANIMATION */}
        <div>
          <LottieAnimation
            animationName="animCongratsCentered" // Adjust based on your naming and files
            xPos={50}
            yPos={18}
            scale={50} // Adjust based on your preference
            zIndex={-5000} // Ensure it's visible as needed
            opacity={100}
            boolLoop={false}
            useThemeColors="theme"
          />
        </div>

        <Grid item>
          <img src="/success-icon.png" alt="Success Icon" style={{ width: '100px', height: '100px' }} />
        </Grid>
        <Grid item>
          <Typography variant="h5" align="center">
            Payment successful!
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h1" className="text-gradient" sx={{ marginTop: 3 }}>
            Thank You!
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h6" paddingTop="20px" align="center" sx={{ maxWidth: '50rem' }}>
            Your personalized Numerology Personality Decoder Report will be delivered to your email within the next 5 minutes.
          </Typography>
        </Grid>
        <Grid item display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <div className="num-result-spin-animation-sacred-animation">
            <img src="/media/gfx/sacred-geometry-001.png" alt="Sacred Geometry" style={{ width: '200px', height: '200px', marginTop: '-50px' }} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentSuccessPage;
