import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyAsFl-i0UqR4ptaLm4JD9eYLooD3AilmYo',
  authDomain: 'mystic-2023.firebaseapp.com',
  projectId: 'mystic-2023',
  storageBucket: 'mystic-2023.appspot.com',
  messagingSenderId: '607869023222',
  appId: '1:607869023222:web:d72cb2552ecdc586814dd7',
  measurementId: 'G-6SXE1ZNLWE',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const db = getFirestore(app);

if (process.env.REACT_APP_NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
}

// Configure Firestore to use the online instance
// db.settings({
//   useFetchStreams: false,
// });

export { functions, db, collection, addDoc };
